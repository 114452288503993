import React, { useEffect, useState } from "react";

import AmountStats from "./components/AmountStats";
import DashboardStats from "./components/DashboardStats";

import CircleStackIcon from "@heroicons/react/24/outline/CircleStackIcon";
import CreditCardIcon from "@heroicons/react/24/outline/CreditCardIcon";
import UserGroupIcon from "@heroicons/react/24/outline/UserGroupIcon";
import UsersIcon from "@heroicons/react/24/outline/UsersIcon";
import axios from "axios";
import LastVisitedStores from "./components/LastVisitedStores";
import ReferedPerson from "./components/ReferedPerson";
import TopEarningRef from "./components/TopEarningRef";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [withdrawData, setWithdrawData] = useState([]);
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    console.log("Dashboard er access token:", accessToken);

    // Fetch profile data
    axios
      .get("https://ap.markdowntoday.com/api/profile", config)
      .then((response) => {
        setProfile(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    // Fetch withdraw data
    axios
      .get("https://ap.markdowntoday.com/api/withdraw", config)
      .then((response) => {
        setWithdrawData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      const pendingSum = withdrawData.reduce((sum, data) => {
        if (data.status === "pending") {
          return sum + parseFloat(data.amount);
        }
        return sum;
      }, 0);

      const completedSum = withdrawData.reduce((sum, data) => {
        if (data.status === "completed") {
          return sum + parseFloat(data.amount);
        }
        return sum;
      }, 0);

      const totalBalanceValue = (
        +profile?.affilate_income + +pendingSum.toFixed(2)
      ).toFixed(2);
      const totalBalance = isNaN(totalBalanceValue) ? "0.00" : totalBalanceValue;

      const newStatsData = [
        {
          title: "Total Balance",
          value: totalBalance,
          icon: <UsersIcon className="w-8 h-8" />,
          description: "↙ 0.00 (0%)",
        },
        {
          title: "Available Balance",
          value: profile?.affilate_income || "0.00",
          icon: <UserGroupIcon className="w-8 h-8" />,
          description: "↗︎ 2300 (22%)",
        },
        {
          title: "Pending",
          value: pendingSum?.toFixed(2) || "0.00",
          icon: <CreditCardIcon className="w-8 h-8" />,
          description: "Current month",
        },
        {
          title: "Withdrawal",
          value: completedSum?.toFixed(2) || "0.00",
          icon: <CircleStackIcon className="w-8 h-8" />,
          description: "50 in hot leads",
        },
      ];

      setStatsData(newStatsData);
      console.log("Pending sum:", pendingSum);
      console.log("Completed sum:", completedSum);
    }
  }, [loading, withdrawData, profile]);

  document.title = "MarkDownToday - Dashboard";
  return (
    <div className="px-5">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="grid lg:grid-cols-4 mt-8 md:grid-cols-2 grid-cols-1 gap-3">
            {statsData.map((d, k) => {
              return <DashboardStats key={k} {...d} colorIndex={k} />;
            })}
          </div>
          <AmountStats />
          <LastVisitedStores />
          <ReferedPerson />
          <TopEarningRef />
        </>
      )}
    </div>
  );
};

export default Dashboard;
