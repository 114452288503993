import React from "react";

const AppPolicy = () => {
  document.title = "App Policy";
  return (
    <div className="px-40 py-10">
      <div className="text-center">
        <h1 className="font-bold text-2xl mb-10">
          App POLICY
          <hr />
        </h1>
      </div>
      {/* <p className="mb-3">Markdowntoday.com Refer-a-Friend bonus program allows for members to receive a monetary reward for participating in the program. Markdowntoday.com members will earn a $30 Refer-a-Friend bonus for each qualified new member they refer to. Existing Markdowntoday.com members are not eligible as new referrals.</p>
            <p className="mb-3">To qualify for a $30, Refer-a-Friend bonus, new referrals must click on your unique Refer-a-Friend link, join Markdowntoday.com and earn $10 or more in Cash Back (not including bonuses and earnings from non-purchase activity) within 365 days of account creation. Each new member referred through the Refer-a-Friend program will be eligible for a $10 Sign-Up bonus. To qualify for a $10 Sign-Up bonus, please refer to the <span className="text-red-600">Markdowntoday.com Terms &amp; Conditions</span>.</p>
            <p className="mb-3">Additionally, existing Markdowntoday.com members may qualify for a $30 Refer-a-Friend bonus for referring new members to Markdowntoday.com Free Product Offers. To receive the bonus, a referred new member needs to complete the offer. For more information on Free Product Offers see here. New members who complete a Free Product Offer are not eligible to receive a Sign-Up Bonus. Accounts will be terminated and bonuses forfeited for improper use of the Refer-a-Friend program, including, but not limited to: using search engine sponsored listings or any other paid advertising; creating multiple accounts for one individual; posting your referral link on merchant&#39;s site, merchant&#39;s social media page or other similar activity; impersonating Markdowntoday.com; spamming web forums and other online properties. Determination of bonus eligibility will be at the sole discretion of Markdowntoday.com. The Refer-a-Friend program may be altered and/or discontinued at any time. Terms subject to change. See the <span className="text-red-600">Markdowntoday.com Terms &amp; Conditions</span> for more information.</p> */}

      <p className="mb-3">
        This privacy policy explains how Carociba Inc. we collect, use, and
        share your personal information when you use our Markdowntoday app .
      </p>

      <h1 className="font-bold text-2xl mb-3">Information We Collect</h1>

      <p className="mb-2">We collect the following types of information from you:</p>

      <ul className="flex flex-col items-start mb-3 list-disc pl-4">
        <li>
          Personal information you provide to us, such as your name, email
          address, and phone number.
        </li>
        <li>
          Log data, such as your IP address, device information, and how you use
          the Service.
        </li>
        <li>Cookies and other tracking technologies.</li>
      </ul>

      <h1 className="font-bold text-2xl mb-3">How We Use Your Information</h1>


      <p className="mb-2">We use your information to:</p>

      <ul className="flex flex-col items-start mb-3 list-disc pl-4">
        <li>Provide and improve the Service.</li>
        <li>Send you promotional emails and other communications.</li>
        <li>Prevent fraud and abuse.</li>
        <li>Analyze how people use the Service.</li>
      </ul>


      <h1 className="font-bold text-2xl mb-3">How We Share Your Information</h1>


      <p className="mb-2">We may share your information with the following third parties:</p>

      <ul className="flex flex-col items-start mb-3 list-disc pl-4">
        <li>Service providers who help us operate the Service.</li>
        <li>
          Law enforcement agencies and other government entities, if required by
          law.
        </li>
      </ul>


      <h1 className="font-bold text-2xl mb-3">Your Choices</h1>


      <p className="mb-2">You have the following choices about how we use your information:</p>

      <ul className="flex flex-col items-start mb-3 list-disc pl-4">
        <li>
          You can opt out of receiving promotional emails from us by clicking
          the unsubscribe link in any email.
        </li>
        <li>You can manage your cookie settings in your browser.</li>
      </ul>


      <h1 className="font-bold text-2xl mb-3">Data Security</h1>

      <p className="mb-2">
        We take reasonable measures to protect your information from
        unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or electronic storage is 100% secure.
      </p>


      <h1 className="font-bold text-2xl mb-3">Changes to This Privacy Policy</h1>


      <p className="mb-2">
        We may update this privacy policy from time to time. If we make any
        significant changes, we will notify you by posting the updated privacy
        policy on our website.
      </p>

      <h1 className="font-bold text-2xl mb-3">Contact Us</h1>

      <p className="mb-2">
        If you have any questions about this privacy policy, please contact us
        at info@markdowntoday.com.
      </p>
    </div>
  );
};

export default AppPolicy;
