import React from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BBBLogo from "../../../Assets/bbb.png";
import ChatbotLogo from "../../../Assets/chatbot.png";

import { useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaTiktok,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { BASE_URL } from "../../../api/api";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    try {
      // Make an API request to subscribe the email
      const response = await fetch(`${BASE_URL}/subscriber/store`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        // Email subscription successful
        toast.success("Successfully subscribed to the newsletter!");
        setEmail("");
      } else {
        // Email subscription failed
        toast.error("Failed to subscribe. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLiveChatClick = () => {
    // Replace this with the link you want to open in a popup window
    const liveChatLink = "https://tawk.to/chat/64aba843cc26a871b0276757/1h4v8iifp";
    const width = 400; // Specify the desired width of the popup window
    const height = 600; // Specify the desired height of the popup window
    const left = (window.innerWidth - width) / 2; // Calculate the horizontal position to center the window
    const top = (window.innerHeight - height) / 2; // Calculate the vertical position to center the window
    window.open(liveChatLink, "_blank", `width=${width},height=${height},left=${left},top=${top}`);
  };

  return (
    <div
      className="md:mt-2 md:mb-0  mb-10"
      style={{ fontFamily: "Open Sans, sans-serif" }}
    >
      <ToastContainer />

      <div className="block md:hidden bg-zinc-950">
        <footer className="footer px-6 pt-4 bg-zinc-950 text-white text-[9px] flex justify-between ">
          <div className="">
            <span className="footer-title my-1">Company</span>
            <Link
              onClick={handleLinkClick}
              to="/about"
              className="link link-hover"
            >
              About us
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/contact"
              className="link link-hover"
            >
              Contact
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/job"
              className="link link-hover"
            >
              Jobs
            </Link>
            <Link className="link link-hover">info@markdowntoday.com</Link>
            <Link className="link link-hover">referral@markdowntoday.com</Link>
          </div>
          <div className="">
            <span className="footer-title my-1">Services</span>
            <Link
              onClick={handleLinkClick}
              to="/referral"
              className="link link-hover"
            >
              Refer & Earn
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/faq"
              className="link link-hover"
            >
              FAQ
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/email-preferences"
              className="link link-hover"
            >
              Email Preferences
            </Link>
            <span className="link link-hover" onClick={handleLiveChatClick}>
              Live Chat
            </span>
            <Link
              onClick={handleLinkClick}
              to="/press"
              className="link link-hover"
            >
              Press kit
            </Link>
          </div>
          <div>
            <div>
              <span className="footer-title flex justify-center items-center gap-3">
                Legal
                <Link to="https://tawk.to/chat/64aba843cc26a871b0276757/1h4v8iifp">
                  <img
                    src={ChatbotLogo}
                    alt="Chatbot Logo"
                    className="w-6 h-6"
                  />
                </Link>
              </span>
              {/* Legal links */}
              {/* Add chatbot logo here */}
            </div>
            <Link
              onClick={handleLinkClick}
              className="link link-hover"
              to="/terms"
            >
              Terms of use
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/privacy-policy"
              className="link link-hover"
            >
              Privacy policy
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/cookie-policy"
              className="link link-hover"
            >
              Cookie policy
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/referral-policy"
              className="link link-hover"
            >
              Referral policy
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/app-policy"
              className="link link-hover"
            >
              App policy
            </Link>
            <Link
              onClick={handleLinkClick}
              to="/advertisement-disclosure"
              className="link link-hover"
            >
              Advertisement Disclosure
            </Link>
          </div>
        </footer>
        <div className="max-w-sm p-6 bg-zinc-950">
          <span className="footer-title">Newsletter</span>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-white md:text-base">
                Enter your email address
              </span>
            </label>
            <div className="relative flex">
              <input
                type="text"
                placeholder="username@site.com"
                className="input input-bordered w-full pr-16"
                value={email}
                onChange={handleEmailChange}
              />

              <button
                className="btn bg-red-800 absolute top-0 right-0 rounded-l-none text-white font-bold md:text-base"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="mt-4 flex space-x-2">
            <a
              href="https://www.facebook.com/markdowntoday/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaFacebook className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.instagram.com/markdowntoday/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-pink-500 p-2 rounded-full hover:bg-pink-600 transition-colors"
            >
              <FaInstagram className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.tiktok.com/@markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-pink-500 p-2 rounded-full hover:bg-pink-600 transition-colors"
            >
              <FaTiktok className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZsQn1I-jnVwicsNhepmOPA"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition-colors"
            >
              <FaYoutube className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.linkedin.com/company/markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaLinkedin className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://twitter.com/markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaTwitter className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://web.whatsapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 p-2 rounded-full hover:bg-green-600 transition-colors"
            >
              <FaWhatsapp className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.pinterest.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition-colors"
            >
              <FaPinterest className="h-4 w-4 text-white md:text-base" />
            </a>
          </div>
        </div>
      </div>

      <footer className="footer p-10  bg-zinc-950 text-white md:text-base md:flex md:justify-between hidden md:block">
        <div>
          <span className="footer-title">Newsletter</span>
          <div className="form-control w-full">
            <label className="label">
              <span className="label-text text-white md:text-base">
                Enter your email address
              </span>
            </label>
            <div className="relative flex">
              <input
                type="text"
                placeholder="username@site.com"
                className="input input-bordered w-full pr-16"
                value={email}
                onChange={handleEmailChange}
              />

              <button
                className="btn bg-red-800 absolute top-0 right-0 rounded-l-none text-white font-bold md:text-base"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
          </div>
          <div className="mt-4 flex space-x-2">
            <a
              href="https://www.facebook.com/markdowntoday/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaFacebook className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.instagram.com/markdowntoday/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-pink-500 p-2 rounded-full hover:bg-pink-600 transition-colors"
            >
              <FaInstagram className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.tiktok.com/@markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-pink-500 p-2 rounded-full hover:bg-pink-600 transition-colors"
            >
              <FaTiktok className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZsQn1I-jnVwicsNhepmOPA"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition-colors"
            >
              <FaYoutube className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.linkedin.com/company/markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaLinkedin className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://twitter.com/markdowntoday"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 p-2 rounded-full hover:bg-blue-600 transition-colors"
            >
              <FaTwitter className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://web.whatsapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-green-500 p-2 rounded-full hover:bg-green-600 transition-colors"
            >
              <FaWhatsapp className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
            <a
              href="https://www.pinterest.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-red-500 p-2 rounded-full hover:bg-red-600 transition-colors"
            >
              <FaPinterest className="h-4 w-4 text-white font-bold md:text-base" />
            </a>
          </div>
        </div>
        <div>
          <span className="footer-title">Company</span>
          <Link
            onClick={handleLinkClick}
            to="/about"
            className="link link-hover"
          >
            About us
          </Link>
          <Link
            onClick={handleLinkClick}
            to="/contact"
            className="link link-hover"
          >
            Contact
          </Link>
          <Link onClick={handleLinkClick} to="/job" className="link link-hover">
            Jobs
          </Link>
          <Link className="link link-hover">info@markdowntoday.com</Link>
          <Link className="link link-hover">referral@markdowntoday.com</Link>
        </div>
        <div>
          <span className="footer-title">Services</span>
          <Link
            onClick={handleLinkClick}
            to="/referral"
            className="link link-hover"
          >
            Refer & Earn
          </Link>
          <Link onClick={handleLinkClick} to="/faq" className="link link-hover">
            FAQ
          </Link>
          <Link
            onClick={handleLinkClick}
            to="/email-preferences"
            className="link link-hover"
          >
            Email Preferences
          </Link>
          <span className="link link-hover" onClick={handleLiveChatClick}>
            Live Chat
          </span>
          <Link
            onClick={handleLinkClick}
            to="/press"
            className="link link-hover"
          >
            Press kit
          </Link>
        </div>
        <div>
          <div>
            <span className="footer-title flex justify-center items-center gap-3">
              Legal
              <span className="cursor-pointer" onClick={handleLiveChatClick}>
                <img src={ChatbotLogo} alt="Chatbot Logo" className="w-6 h-6" />
              </span>
            </span>
            {/* Legal links */}
            {/* Add chatbot logo here */}
          </div>
          <Link
            onClick={handleLinkClick}
            className="link link-hover"
            to="/terms"
          >
            Terms of use
          </Link>
          <Link
            onClick={handleLinkClick}
            to="/privacy-policy"
            className="link link-hover"
          >
            Privacy policy
          </Link>
          <Link
            onClick={handleLinkClick}
            to="/cookie-policy"
            className="link link-hover"
          >
            Cookie policy
          </Link>
          <Link
            onClick={handleLinkClick}
            to="/referral-policy"
            className="link link-hover"
          >
            Referral policy
          </Link>
          <Link
              onClick={handleLinkClick}
              to="/app-policy"
              className="link link-hover"
            >
              App policy
            </Link>
          <Link
            onClick={handleLinkClick}
            to="/advertisement-disclosure"
            className="link link-hover"
          >
            Advertisement Disclosure
          </Link>
        </div>
      </footer>

      <footer className="footer footer-center pb-10 bg-zinc-950 text-white md:text-base md:flex md:justify-center items-center md:px-10">
        <div>
          <p>
            Copyright © 2023 - All right reserved by{" "}
            <span className="text-blue-600">
              <Link onClick={handleLinkClick} to="/">
                Markdowntoday
              </Link>
            </span>
          </p>
        </div>

        {/* Vertical Divider */}
        <div className="border border-white h-6 mx-3 hidden md:block" />

        <div className="flex justify-center">
          <img src={BBBLogo} alt="BBB Logo" className="w-full h-8" />
        </div>
      </footer>
    </div>
  );
};

export default Footer;
