import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaPinterest,
  FaRegTimesCircle,
  FaShareAlt,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { animated, useSpring } from "react-spring";
import NoCouponsMessage from "./NoCouponsMessage";

const Coupon = () => {
  const { name } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [couponData, setCouponData] = useState([]);
  const [copiedCouponCode, setCopiedCouponCode] = useState("");
  const [openShareButtons, setOpenShareButtons] = useState({});

  // Number of items to display per page
  const itemsPerPage = 3;

  useEffect(() => {
    // Fetch coupon data from the API
    axios
      .get(`https://ap.markdowntoday.com/api/get-coupons/${name}`)
      .then((response) => {
        const data = response.data;
        // Filter out items where "coupons" array is empty
        const filteredData = data.filter((item) => item.coupons.length > 0);
        setCouponData(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching coupon data:", error);
      });
  }, [name]);

  // Calculate the index of the first and last item to display on the current page
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  // Slice the coupon data array to get the items for the current page
  const currentCoupons = couponData.slice(firstIndex, lastIndex);

  // Function to handle page navigation
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to copy the coupon code to the clipboard
  const copyCouponCode = (code) => {
    navigator.clipboard.writeText(code);
    setCopiedCouponCode(code);
  };

  // Function to toggle share buttons visibility for a specific coupon
  const toggleShareButtons = (couponId) => {
    setOpenShareButtons((prevState) => ({
      ...prevState,
      [couponId]: !prevState[couponId],
    }));
  };

  const shareButtonsAnimation = useSpring({
    opacity: 1,
    maxHeight: "100px",
    overflow: "hidden",
  });

  return (
    <>
      {couponData.length === 0 ? (
        <>
          <NoCouponsMessage></NoCouponsMessage>
        </>
      ) : (
        <>
          {couponData.map((item) => (
            <div
              key={item.id}
              className="bg-white rounded-lg shadow-md overflow-hidden mt-8 mb-8 mx-10"
            >
              {item.coupons.map((coupon) => (
                <div key={coupon.id}>
                  <div className="bg-gradient-to-r from-red-500 to-orange-500 px-4 py-3 flex items-center justify-between">
                    <div className="text-white font-bold text-base sm:text-lg">
                      {coupon.type === 0 ? "P" : coupon.type === 1 ? "D" : ""}
                    </div>
                    <div className="text-white text-xs sm:text-sm">
                      Expires: {coupon.end_date}
                    </div>
                    <div className="text-white text-xs sm:text-sm">
                      Started From: {coupon.start_date}
                    </div>

                    <div className="flex items-center space-x-2">
                      <button
                        className="text-white hover:text-gray-200 p-2 rounded-full"
                        style={{ backgroundColor: "#1091F3" }}
                        onClick={() => toggleShareButtons(coupon.id)}
                      >
                        {openShareButtons[coupon.id] ? (
                          // FaRegTimesCircle icon for closing the share buttons
                          <FaRegTimesCircle size={16} />
                        ) : (
                          // FaShareAlt icon for displaying the share buttons
                          <FaShareAlt size={16} />
                        )}
                      </button>
                    </div>

                    {/* Share Buttons */}
                    {openShareButtons[coupon.id] && (
                      <animated.div
                        className="flex items-center space-x-2"
                        style={shareButtonsAnimation}
                      >
                        {/* Facebook */}
                        <FacebookShareButton
                          url={`at @${item.name}. Plus earn ${coupon.price}% cash back. https://www.markdowntoday.com/coupon/${coupon.id} via @Markdowntoday`}
                          quote={`at @${item.name}. Plus earn ${coupon.price}% cash back. https://www.markdowntoday.com/coupon/${coupon.id} via @Markdowntoday`}
                          // hashtag="#YourHashtag"
                        >
                          <button
                            className="text-white hover:text-gray-200 p-2 rounded-full"
                            style={{ backgroundColor: "#1091F3" }}
                          >
                            <FaFacebook size={17} />
                          </button>
                        </FacebookShareButton>

                        {/* Twitter */}
                        <TwitterShareButton
                          url={`at @${item.name}. Plus earn ${coupon.price}% cash back. https://www.markdowntoday.com/coupon/${coupon.id} via @Markdowntoday`}
                          title={coupon.coupon_title}
                          // hashtags={["YourHashtag1", "YourHashtag2"]}
                        >
                          <button
                            className="text-white hover:text-gray-200 p-2 rounded-full"
                            style={{ backgroundColor: "#1da1f2" }}
                          >
                            <FaTwitter size={16} />
                          </button>
                        </TwitterShareButton>

                        {/* Whatsapp */}
                        <WhatsappShareButton
                          url={`at @${item.name}. Plus earn ${coupon.price}% cash back. https://www.markdowntoday.com/coupon/${coupon.id} via @Markdowntoday`}
                          title={coupon.coupon_title}
                        >
                          <button
                            className="text-white hover:text-gray-200 p-2 rounded-full"
                            style={{ backgroundColor: "#25d366" }}
                          >
                            <FaWhatsapp size={16} />
                          </button>
                        </WhatsappShareButton>

                        {/* Pinterest */}
                        <PinterestShareButton
                          url={`at @${item.name}. Plus earn ${coupon.price}% cash back. https://www.markdowntoday.com/coupon/${coupon.id} via @Markdowntoday`}
                          media={coupon.image_url}
                          description={coupon.coupon_title}
                        >
                          <button
                            className="text-white hover:text-gray-200 p-2 rounded-full"
                            style={{ backgroundColor: "#bd081c" }}
                          >
                            <FaPinterest size={16} />
                          </button>
                        </PinterestShareButton>
                      </animated.div>
                    )}
                    {/* End Share Buttons */}
                  </div>
                  <div className="p-4 flex flex-col sm:flex-row justify-between">
                    <div className="flex items-center mb-4">
                      <img
                        src={coupon.image_url}
                        alt="Logo"
                        className="h-8 sm:h-20 mr-4 sm:mr-7"
                      />
                      <div>
                        <div className="text-sm sm:text-base font-bold mb-2">
                          {coupon.coupon_title}
                        </div>
                        <div className="text-gray-600 max-w-2xl text-xs sm:text-sm">
                          {coupon.description}
                        </div>
                      </div>
                    </div>
                    <button
                      className="bg-red-500 hover:bg-orange-600 text-white btn rounded-lg shadow"
                      onClick={() => copyCouponCode(coupon.code)}
                    >
                      {copiedCouponCode === coupon.code
                        ? "Copied!"
                        : "Get Coupon"}
                    </button>
                  </div>
                  <div className="bg-gray-200 px-4 py-3 flex items-center justify-between text-green-600 font-semibold text-xs sm:text-sm">
                    <div>
                      Plus{" "}
                      {coupon.type === "0"
                        ? `${coupon.price}%`
                        : coupon.type === "1"
                        ? `$${coupon.price}`
                        : ""}{" "}
                      Cash Back With Markdowntoday
                    </div>
                    <div>
                      <Link
                        to="/referral"
                        className="text-indigo-500 hover:text-indigo-600"
                      >
                        Refer & Earn
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}

          {/* Pagination */}
          <div className="flex justify-center mt-8">
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-l text-xs sm:text-base"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-600 font-bold py-2 px-4 rounded-r ml-1 text-xs sm:text-base"
              disabled={currentCoupons.length < itemsPerPage}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Coupon;
