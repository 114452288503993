import React, { useEffect, useState } from 'react';
import product3 from '../../../Assets/product.png';

const ProductDetails =({  })=>{
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch('https://ap.markdowntoday.com/api/scraped-products')
      .then(response => response.json())
      .then(data => setData(data));
  }, []);
  console.log("Dataaaa:", data);
    const items2 = [...Array(4)];
    return(
        <div className='my-20 px-36'>
           <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1'> 
                <div >
                   <div className='flex justify-start items-center gap-6'>
                    <span className=" custom-Badge top-0 right-4 inline-block  bg-red-500 text-white  text-md font-bold px-1">DEAL
                    <h2 className='mt-3 text-xs'>20 min </h2>
                    <h2 className='text-center  text-xs'>left</h2>
                    </span>
                    <div>
                    <h2 className='text-black font-bold text-3xl'>Up to 80% Off Fragrance Sets
                    </h2>  
                    <div className='flex justify-between items-center'>
                    <div className='flex justify-start items-center gap-1 '>
                    <h2 className=' text-2xl text-[#ED3A58]  font-bold'>$<span className='GradiantTextColour2'>24</span></h2>  
                    <h2 className='line-through text-[#A0A0A0]    text-sm'>$166</h2>
                    </div>
                    <h2>Free Shipping on orders $25+</h2>
                  </div>
                </div>
                  </div> 
                  <div className='rounded-xl shadow-[8px_9px_20px_10px_rgba(70,177,157,0.35)]  my-10'>
                    <img src={product3} alt=''></img>
                  </div>
                 
                   
                </div>

                <div className='flex justify-center items-center w-11/12 mx-auto ml-16'>
                <div className=''>
                    <h2 className='text-sm'>Save up to 80% on select Women's Fragrance Gift Sets on sale for</h2>
                    <h2 className='text-sm'><span className='GradiantTextColour text-xl'>$23.70 </span>
                     at Macy's. There are sets from Catherine Malandrino, Nanette Lepore, English Laundry and more included in the sale. Shipping is free with your free Macy's Star Rewards account.</h2>
                    <h2 className='text-sm mt-6'>Our Top Picks: </h2>
                   
                        <h2 className='text-sm'>3-Pc Catherine Malandrino Special Moments Gift Set - $23.70</h2>
                        <h2 className='text-sm'>4-Pc Nanette Lepore Beauty Abroad Gift Set - $23.70</h2>
                        <h2 className='text-sm'>4-Pc Kensie Zest for Life Gift Set - $23.70</h2>
                       
            <button className='bg-[#ED3A58] px-4 text-md rounded-sm text-white mt-8'>Get the Deal</button>
                </div>

                </div>
           </div>
           <h2 className='text-black font-bold text-2xl my-4 '>Recommended stores</h2>
           <div className='grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4 mt-6'>

          {data.map((product) => (
        
              <div key={product.id} product={product} className = "relative rounded-xl shadow-[8px_9px_20px_10px_rgba(70,177,157,0.35)] py-6" >
                <span className="absolute custom-Badge top-0 right-4 inline-block  bg-red-500 text-white  text-md font-bold px-1">DEAL
                <h2 className='mt-3 text-xs'>{product.time} min </h2>
                <h2 className='text-center  text-xs'>left</h2>
                </span>
                <div className=" flex justify-center items-start">
                    <img className='' src={product.image} alt=''></img>
                    
                </div>
                {/* <h2 className='text-center'>{product.CashBack}</h2> */}
                <div className='flex justify-start items-center gap-4 px-10 my-8'>
                  <h2 className=' text-4xl text-[#ED3A58] font-bold'>$<span className='GradiantTextColour2'>{product.price}</span></h2>
                </div>
                
                </div>
        
          ))}
          
            </div>
     
            {/* {
                courseDetails.map(c => <CourseDetailsSingle
                key={c._id}
                c={c}
                ></CourseDetailsSingle>)
            } */}
        </div>
    )
}
export default ProductDetails;