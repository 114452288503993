import axios from "axios";
import React, { useEffect, useState } from "react";

const WithdrawTable = () => {
  const [withdrawData, setWithdrawData] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get("https://ap.markdowntoday.com/api/withdraw", config)
      .then((response) => {
        setWithdrawData(response.data); // Assuming the response contains an array of withdraw data
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    const date = new Date(createdAt);
    return date.toLocaleDateString(); // Format the date as a string in the user's local format
  };

  return (
    <div className="table-responsive max-w-lg">
      <table className="table table-sm max-w-lg">
        <thead>
          <tr>
            <th>#</th>
            <th>Method</th>
            <th>Account Email</th>
            <th>IBAN</th>
            <th>Account Name</th>
            <th>Swift</th>
            <th>Amount</th>
            <th>Fee</th>
            <th>Status</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {withdrawData.map((withdraw, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{withdraw.method}</td>
              <td>{withdraw.acc_email}</td>
              <td>{withdraw.iban}</td>
              <td>{withdraw.acc_name}</td>
              <td>{withdraw.swift}</td>
              <td>{withdraw.amount}</td>
              <td>{withdraw.fee}</td>
              <td>{withdraw.status}</td>
              <td>{formatCreatedAt(withdraw.created_at)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawTable;
