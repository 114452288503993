import React, { useState } from 'react';
import whatsNewData from "../../../src/api/whatsNewApi";
import { Link } from 'react-router-dom';

const BlogSection = () => {
  document.title = "What's New - MarkDownToday";
  

  const postsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  // Pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = whatsNewData.slice(indexOfFirstPost, indexOfLastPost);

  const totalPages = Math.ceil(whatsNewData.length / postsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto my-10 px-10">
      <h2 className="text-3xl font-bold mb-8 text-center">What's New</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {currentPosts.map((post) => (
          <div
            key={post.id}
            className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:scale-105"
          >
            <img
              className="w-full h-48 object-cover"
              src={post.image}
              alt={post.title}
            />
            <div className="p-6">
              <h3 className="text-xl font-bold mb-2">{post.title}</h3>
              <p className="text-gray-600 mb-4">
                {post.content
                  .split(' ')
                  .slice(0, 20)
                  .join(' ')}
                {post.content.split(' ').length > 20 && '...'}
              </p>
              <div className="flex justify-end">
                <Link
                  className="text-blue-500 font-semibold hover:text-blue-700 transition duration-300 ease-in-out"
                  to={`/whatsnew/${post.id}`}
                >
                  See More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-8">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`mx-2 px-4 py-2 rounded-full ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 text-gray-700'
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
